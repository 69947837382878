// extracted by mini-css-extract-plugin
export var gridContainer = "Conf-module--gridContainer--3Ghnm";
export var grid = "Conf-module--grid--JhPE4";
export var numbers = "Conf-module--numbers--1a3ZX";
export var number = "Conf-module--number--3nSDn";
export var item = "Conf-module--item---f-JS";
export var flagIcon = "Conf-module--flagIcon--1cekR";
export var materialIcons = "Conf-module--materialIcons--3ABn5";
export var gridItem = "Conf-module--gridItem--2itMV";
export var mqSmall = "Conf-module--mqSmall--2yLcq";
export var containerConf = "Conf-module--containerConf--3ymDM";
export var topSmall = "Conf-module--topSmall--1tGMK";
export var mqBig = "Conf-module--mqBig--352en";
export var bottomSmall = "Conf-module--bottomSmall--2rxkM";